import React from "react";
import Navbar from "./components/Navbar/Navbar";
import "./App.css"
import AllRoutes from "./AllRoutes";

function App() {
  return (
    <>
      {/* <Navbar /> */}
      <AllRoutes/>
    </>
  );
}

export default App;
